<template>
  <div>
    <b-card
      title="รายงานสรุปยอดบัญชี"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="ช่วงวันเวลาที่ต้องการสรุปยอด"
                label-for="mc-country"
              >
                <b-row>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.startdate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.enddate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
          </b-row>
          <b-row>
            <b-col md="6">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="calculateAccountSummary"
              >
                สรุปยอดบัญชี
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <div
      v-for="summaryRow in accountSummaryList"
      :key="`summary-${summaryRow.startdate}-${summaryRow.enddate}`"
    >
      <b-card-actions
        :title="`รายงานสรุปยอดบัญชี ช่วง ${summaryRow.startdate} - ${summaryRow.enddate}`"
        action-collapse
        action-close
      >
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :busy.sync="isBusy"
          :per-page="tableQuery.page_size"
          :current-page="tableQuery.page"
          :items="Object.values(summaryRow.bankaccounts)"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(processing)="data">
            <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.processing ? 'N': 'Y']">
              {{ {'Y':'สรุปยอดเสร็จสิ้น', 'N':'ยังคำนวนอยู่'}[data.item.processing ? 'N': 'Y'] }}
            </b-badge>
          </template>
        </b-table>
      </b-card-actions>
    </div>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BButton, BCardBody, BRow, BCol, BForm, BFormInput, BCollapse,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    // BCollapse,
    BCardActions,
    // vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]'),
      isBusy: false,
      pageOptions: [100, 200, 500, 1000],
      totalRows: 1,
      timerRef: null,
      sortBy: 'created',
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 100,
        startdate: null,
        enddate: null,
      },
      accountSummaryList: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'acc_no', label: 'เลขที่บัญชี' },
        { key: 'bank', label: 'ธนาคาร' },
        { key: 'acc_name', label: 'ชื่อบัญชี' },
        { key: 'alias', label: 'ชื่อย่อ' },
        {
          key: 'deposit_count',
          label: 'จำนวนรายการฝาก',
          tdClass: 'td-currency',
        },
        {
          key: 'deposit_sum',
          label: 'ยอดฝากรวม',
          tdClass: 'td-currency',
          formatter: val => this.numberWithCommas(val),
        },
        { key: 'withdraw_count', label: 'จำนวนรายการถอน', tdClass: 'td-currency' },
        {
          key: 'withdraw_sum',
          label: 'ยอดถอนรวม',
          tdClass: 'td-currency',
          formatter: val => this.numberWithCommas(val),
        },
        { key: 'processing', label: 'ดำเนินการ', tdClass: 'td-currency' },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    // getBankOptions() {
    //   return this.bankListOptions
    // },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.fetchMember()
    this.fetchAccountSummaryList()
    this.reloadFetchAccountSum()
  },
  beforeDestroy() {
    clearTimeout(this.timerRef)
    this.timerRef = null
  },
  methods: {
    reloadFetchAccountSum() {
      clearTimeout(this.timerRef)
      this.timerRef = null
      const ctx = this
      this.timerRef = setTimeout(() => {
        if (ctx.timerRef == null)
          return
        ctx.fetchAccountSummaryList()
        ctx.reloadFetchAccountSum()
      }, 30000)
    },
    calculateAccountSummary() {
      // this.$refs.membertable.refresh()
      console.log(this.tableQuery)

      if (!this.tableQuery.startdate || !this.tableQuery.enddate) {
        this.$swal({
          title: 'สรุปยอดบัญชี',
          text: 'กรุณาเลือกวันที่และช่วงเวลาที่ต้องการสรุปยอดบัญชี',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'ตกลง',
          cancelButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      const ctx = this
      this.$swal({
        title: 'สรุปยอดบัญชี',
        text: `คุณยืนยันจะสรุปยอดทุกบัญชี ตั้งแต่ ${moment(this.tableQuery.startdate).format('DD/MM/YYYY HH:mm:ss')} ถึง ${moment(this.tableQuery.enddate).format('DD/MM/YYYY HH:mm:ss')} ใช่หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่ ตกลง',
        cancelButtonText: 'ไม่ใช่',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value)
          return this.$http.post('account-summary', this.tableQuery)
        return { data: { success: false } }
      }).then(({ data: result }) => {
        if (result.success) {
          ctx.fetchAccountSummaryList()
        }
      })
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.$refs.membertable.refresh()
    },
    async fetchAccountSummaryList() {
      try {
        const { data: { success, summaryLists } } = await this.$http.get('account-summary')
        if (success)
          this.accountSummaryList = summaryLists
      } catch (err) {
        console.log(err)
      }
    },
    async fetchMember(ctx) {
      console.log('[fetchMember]', ctx)
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.tableQuery[key].value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }

      const { data: _members } = await this.$http.get(`/members${query !== '' ? (`?${query}`) : ''}`)
      this.items = _members.users.data
      this.totalRows = _members.users.total
      return this.items
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.tableQuery.page = 1
    },
    viewMember(data) {
      this.$router.push({ name: 'member-detail', params: { user: data.phoneno } })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.td-currency {
  text-align: right;
}
</style>
